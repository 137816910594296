import React, { FC } from 'react'
import { BrowserRouter as Router, Route, Switch, Link, LinkProps, useLocation } from 'react-router-dom'
import './App.css'
import logo from './kuvat/logo2.png'
import { Naky } from './Naky'
import { Kohteet } from './Kohteet'
import { Materiaali } from './Materiaali'

const Linkki:FC<LinkProps> = (props) => {
  const nykyinenPolku = String(useLocation().pathname)
  const polku = props.to.toString()
  const onkoAktiivinen = String(nykyinenPolku).endsWith(polku)
  return (
    <div>
      <Link {...props} className={`linkki ${onkoAktiivinen && 'aktiivinen'}`}></Link>
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <div id="ylaosa">
        <img id="logo" src={logo} alt="Hallelujah + risti ja maapallo"/>
        <h2 id="kansakunnat">- kansakunnat Kristukselle -</h2>
      </div>
      <Router> 
        <div id="linkit">
          <Linkki to="/" >Näky</Linkki>
          <Linkki to="/kohteet">Työkohteet</Linkki>
          <Linkki to="/materiaali">Materiaali</Linkki>
        </div>
        <Switch>
          <Route path="/kohteet">
            <Kohteet />
          </Route>
          <Route path="/materiaali">
            <Materiaali></Materiaali>
          </Route>
          <Route path="/">
            <Naky />
          </Route>
        </Switch>
      </Router>
      <div id="alapalkki">
        <p>
          Jos haluat tietää lisää, ota yhteyttä
        </p>
        <div id="yhdistys">
          <div id="nimi">Hallelujah ry</div>
          <div id="osoite">jeesusonkuningas@gmail.com</div>
        </div>
        <p>
          Jos haluat tukea työtä, käytä tilinumeroa <br />FI89 5612 1120 6912 14, saaja Hallelujah ry (RA/2022/525)
        </p>
      </div>
    </div>
  );
}

export default App;
