import { FC } from "react"
import React from 'react'
import './Kohteet.css'
import kokouskuva from './kuvat/kokous.jpg'
import pelikuva from './kuvat/peli.jpg'
import lapset from './kuvat/lapset.jpg'
import pastorit from './kuvat/pastorit.jpg'
import raamattuvideo from './kuvat/raamatut.mp4'
import kokousvideo from './kuvat/kokous.mp4'

export const Kohteet:FC = () => {
  return (
    <div id="kohteet">
      <div className="alkuteksti">
        <p>
          Jeesus Kristus käski seuraajiaan saarnaamaan evankeliumia kaikille luoduille.
          Sen vuoksi emme häpeä evankeliumia, sillä se on Jumalan Voima jokaiselle, joka uskoo.
          Tämän pohjalta olemme käyneet lukuisissa maissa Aasiassa, Oseaniassa, Afrikassa ja Euroopassa.
        </p>
      </div>
      <p>
        <img id="kokouskuva" className="alkukuva" src={kokouskuva} alt="Ulkoilmatilaisuus, jossa rukoillaan ihmisten puolesta"/>
        <img className="alkukuva" src={pelikuva} alt="Pelataan pallopeliä lasten kanssa"/>
      </p>
      <div className="kohteetteksti">
        <div id="vankila">
          <div>
            <p>
              Matkoillamme pidämme ulkoilmakokouksia, sekä konferensseja pastoreille
              ja vierailemme seurakunnissa sen mukaan miten seurakuntia meille aukeaa.
              Opetamme erilaisissa raamattukouluissa. Opetusjaksot vaihtelevat yhdestä päivästä viikkoon.
              Pastorikonferenssien osallistujamäärä vaihtelee 20 pastorista aina 200 pastoriin.
            </p>
          
            <p>
              Vankiloissa saarnaamme Jeesusta Kristusta ja annamme uskoontulleille vangeille Raamattuja.
              Samoin ulkoilmakokouksissa jaamme ihmisille Raamattuja.
              Raamattujen antaminen on tärkeä osa työtä, 
              koska monesti paikallisilla ihmisillä ei ole rahaa ostaa Raamattuja, 
              eikä niitä ole kaukaisissa syrjäkylissä tarjolla.
              Monesti olemme ostaneet lähikaupungin kristillisen kirjakaupan kaikki Raamatut
              ja näin saaneet olla siunaukseksi paikallisille yrittäjille.
            </p>
          </div>

          <video height="340px" controls data-reactid=".0.1.0.0">
            <source type="video/mp4" data-reactid=".0.1.0.0.0" src={raamattuvideo}>
            </source>
          </video>

        </div>
        <p>
          Jeesus sanoi: "Taivasten valtakunta on lasten kaltainen." 
          Siksi on suuri etuoikeus käydä opettamassa lapsille Jumalan Sanaa
            ja leikkiä heidän kanssaan.
          Jeesus myös siunasi lapsia laskemalla kätensä heidän päälleen.
          Jeesuksen esimerkin innoittamana rukoilemme lasten kanssa
            laskemalla kätemme heidän päälleen ja he saavat lahjaksi Pyhän Hengen.
          Lasten kanssa laulamme ja tanssimme Jumalan kunniaksi.
        </p>
        <img id="lapset" className="kuva" src={lapset} alt="Iloisten lasten ymäröimänä"/>
        <p>
          Olemme järjestäneet ulkoilmakokouksia, joissa Jeesus on tehnyt ihmeitä.
          Eräässä kokouksessa julistin Jeesusta Kristusta parantajana.
          Ennen saarnaa huusin kielillä kovalla äänellä.
          Silloin sain Pyhältä Hengeltä tiedonsanan, että sokeat silmät aukeavat.
          Julistin sen kuulijoille.
          Kokouksen jälkeen kaksi ihmistä todisti, kuinka Jeesus avasi heidän silmänsä.
          Tämän seurauksena seuraavan päivän kokoukseen tuli nelinkertainen määrä ihmisiä. 
          Saimme rukoilla yli kolmen sadan uskosta osattoman ihmisen puolesta (kuva ylhäällä vasemmalla).
          Ylistys Jeesukselle Kristukselle!
        </p>
        <p>
          Pastorikonferensseissa opetamme syvempää ilmestystä Jumalan Sanasta.
          Pastorit ovat pääsääntöisesti kokeneet saaneensa uutta ilmestystä opetuksesta
            ja ovat hyvin kiitollisia, että tulivat paikan päälle kuuntelemaan ja oppimaan. 
          Pastorikonferensseissa vapautan voiteluani kätten päällepanemisen kautta,
            että he saavat rohkeuden rukoilla sairaita terveeksi ja sairaita myös tulee terveeksi.
          Erään konferenssin jälkeen sain palautetta eräältä pastorilta, että hän meni kadulle
          hyvien uutisten kanssa rukoilemaan sairaiden puolesta. 
          Jeesuksen Kristuksen nimessä sairaita parantui.
        </p>
        <img id="pastorit" className="kuva" src={pastorit} alt="Pastorit valmistautumassa vastaanottamaan opetusta"/>
        <div className="kuvateksti">
          Pastorit valmistautumassa vastaanottamaan opetusta
        </div>
        <p>
          Vierailemme usein ihmisten kodeissa.
          Myös siellä Jeesus on tehnyt paljon ihmeitä. 
          Kerran kokouksen jälkeen menimme erään uskovan kotiin tapaamaan hänen isäänsä, 
            joka oli kuuro.  
          Jaoimme hänelle Jumalan Sanaa ja rukoilimme yhdessä kovalla äänellä.
          Yhtäkkiä korvat aukenivat.
          Hän tuli todella iloiseksi ja alkoi soittelemaan moniin paikkoihin kertoen todistuksen "Nyt voin taas kuulla".
        </p>
        <p>
          Vieraillessamme seurakunnissa rohkaisemme uskovia täyttymään Jumalan tulella
            ja opetamme Jumalan Sanaa.
          Olemme saaneet nähdä, kuinka Jeesus on kastanut ihmisiä Pyhällä Hengellä
            ja tulella. He alkavat puhua uusilla kielillä ensimmäisen kerran. 
          Se on joka kerta ilon täyteinen tapahtuma.
        </p>
        <video id="kokousvideo" height="200px" controls data-reactid=".0.1.0.0">
          <source type="video/mp4" data-reactid=".0.1.0.0.0" src={kokousvideo}>
          </source>
        </video>
      </div>
    </div>
  )
}
