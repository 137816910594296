import { FC } from 'react'
import React from 'react'
import kuva1 from './kuvat/kuva1.jpg'
import seurakunta from './kuvat/seurakunta.jpg'
import './Naky.css'

interface NakyProps{

}

export const Naky:FC<NakyProps> = () => {
  return (
    <div className="naky">
      <div className="alkuteksti">
        <h3>Näky (Habakuk 2:2)</h3>
        <p>Hallelujah Ry:n tarkoituksena on viedä Hyviä Uutisia Herrastamme Jeesuksesta Kristuksesta
        kaikkialle maailmaan ja parantaa kaikkinaisia sairauksia Herramme Jeesuksen Kristuksen nimessä.</p>
      </div>
      <p>
        <img id="kuva1" src={kuva1} alt="Riemullinen Intialaisten joukko vilkuttaa, Janne keskellä"/>
      </p>
      <div className="sisateksti">
        <p>Näyn sain suoraan Jumalalta vuonna 2008 ollessani yhdessä tilaisuudessa, 
          jossa koin kuinka Pyhä Henki sanoi minulle, 
          että minun pitää hypätä ilmaan ystäväni saarnatessa <i>Kristus teissä kirkkauden lähdettä.</i></p>
        <p>Minulla oli valtava sisäisen kamppailu itseni kanssa,
          että miksi hyppäisin ilmaan. Olinhan juro suomalainen mies,
          joka ei hypähtele ilmaan. Kuitenkin Pyhä Henki sanoi minulle uudelleen ja uudelleen,
          että hyppää ilmaan ja lopulta hyppäsin. Silloin täytyin maailman suurimmasta Voimasta, 
          josta myös Jeesus sai syntynsä. 
        </p>
        <ul>
          <li>Kun Jumala ilmestyi maailmalle Jeesuksen muodossa,
          niin Enkeli lähetettiin kertomaan neitsyt Marialle: <i>”Pyhä Henki tulee päällesi ja Korkeimman Voima varjoaa sinut…”</i> </li>
          <li>Jeesus sanoi opetuslapsilleen <i>”Pysykää tässä kaupungissa kunnes teidät puetaan Voimalla Korkeuksista”</i></li>
          <li>Apt 1:8:ssa sanotaan <i>”Te saatte Voiman, kun Pyhä Henki tulee teidän päällenne”</i></li>
        </ul>
        <p>Mie vastaanotin silloin kokouksessa tämän Voiman. Mietiskelin kotona tätä voimaa ja
          luettuani Raamatusta kuinka Jeesus sanoo 
          <i>”He jotka uskovat laskevat kätensä sairaitten päälle ja he tulevat terveiksi, 
          sekä jotka uskovat tekevät samoja tekoja kuin minä teen ja suurempia tekoja…”</i> 
          &nbsp;tajusin että minä uskon, jos kerta Jeesus itse sanoo ”jotka uskovat” 
          ja minussa virtaa maailman suurin Voima jolla on luotu maat ja taivaat,
          niin tällä Voimalla pystyn parantamaan sairaita ja ajamaan ulos riivaajia Herramme Jeesuksen nimessä.
        </p>
        <p>Niinpä lähdin kaduille rukoilemaan sairaita terveiksi saadessani tämän ilmestyksen,
          että meidän tehtävä täällä maailmassa on olla Jeesuksen todistajia ollen samankuvan kaltaisia 
          Jeesuksen kanssa ja mennä kaikkeen maailmaan parantamaan kaikkinaisia raihnauksia ja opettamaan 
          kaikki mitä Jeesus on käskenyt meitä pitämään ja sillä tiellä olemme Jumalan Armosta.</p>
          <p>
            <img id="kuva1" src={seurakunta} alt="Rukousta seurakunnassa, kättenpäällepaneminen"/>
          </p>
      </div>
    </div>
  )
}
