import { FC } from "react"
import React from 'react'
import './Materiaali.css'
import kansi from './kuvat/kansi.jpg'
import taka from './kuvat/taka.jpeg'
import kanava from './kuvat/kanava.png'
import kanava2 from './kuvat/kanava2.png'

export const Materiaali:FC = () => {
  return (
    <div id="materiaali">

      <div id="kirja_osio">
        <h3 id="kirjaotsikko">Kirja: Miten rakennamme Jumalan temppeliä</h3>
        <a className="amazon_linkki" href="https://www.amazon.de/-/en/Janne-Markus-Rinkinen/dp/B0991LGW71/ref=sr_1_1?dchild=1&keywords=miten+rakennamme&qid=1628875368&sr=8-1">
          Tilaa Amazonista
        </a>
        <h3 id="kirjaotsikko">Book: Building the temple of God</h3>
        <a className="amazon_linkki" href="https://www.amazon.com/gp/product/B09M5HS1W6/ref=ox_sc_act_image_1?smid=ATVPDKIKX0DER&psc=1">
          Order in English
        </a>
        <div id="kirjat">
          <img id="kansikuva" src={kansi} alt="Kirjan kansi, jossa kruunu ja risti"/>
          <img id="takakansi" src={taka} alt="Kirjan takakansi"/>
        </div>
      </div>

      <div id="youtube_osio" className="eka_kanava">
        <h3 id="kanava_otsikko">Youtube-kanava: Väkeviä opetuksia Jumalan Sanasta</h3>
        <a id="kanava_linkki" href="https://www.youtube.com/channel/UCa-9SRmrfwd55Oum581rYvg">
          Avaa tästä
        </a>
        <img id="kanava_kuva" src={kanava} alt="Kädet levitettynä, meri taustalla"/>
      </div>

      <div id="youtube_osio" className="toka_kanava">
        <h3 id="kanava_otsikko">English Youtube-channel: Powerful teaching from the word of God</h3>
        <a id="kanava_linkki" href="https://www.youtube.com/channel/UCLP8qj9hfHwg56K9fjaJjpQ">
          Watch
        </a>
        <img id="kanava_kuva2" src={kanava2} alt="Kädet levitettynä, meri taustalla"/>
      </div>

      <div id="raamattukoulu">
      <h3 id="raamattukoulu_otsikko">Online raamattukoulu</h3>
      <p>
        Ota yhteyttä, mikäli haluat parempaa ymmärrystä Jumalan Sanasta ja saada
          syvempää ilmestystä Jumalan hyvyydestä Sanan kautta.
        Raamattukoulu on maksuton ja tapahtuu sähköpostin kautta.
          Saat opiskeltavaksi kaksi kurssia kerrallaan.
        Kursseja on kaikkiaan noin 60-90 riippuen lähtötasostasi.
        Yllätyt varmasti saadessasi uutta ilmestystä sanasta.
        Eteneminen tapahtuu vastaamalla materiaaliin perustuviin kysymyksiin.
      </p>
      </div>
    </div>
  )
}